import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Helmet from 'components/Helmet';
import useSiteMetadata from 'hooks/useSiteMetadata';

export default function SEO({ title, description, keywords, children }) {
  const { i18n } = useTranslation();
  const site = useSiteMetadata();

  return (
    // titleTemplate={`%s - ${site.name}`}
    <Helmet defaultTitle={site.name}>
      <html lang={i18n.language} dir={i18n.dir()} />
      <meta charSet="utf-8" />

      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}

      <meta property="og:type" content="website" />
      <meta property="og:locale" content={i18n.language} />
      <meta property="og:site_name" content={site.name} />
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {(title || description) && <meta name="twitter:card" content="summary" />}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {site.social.twitter && (
        <meta name="twitter:site" content={`@${site.social.twitter}`} />
      )}

      <meta name="propeller" content="369ae087e7e28f4c695952320c99f0c2" />

      {children}
    </Helmet>
  );
}
