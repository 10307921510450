import React from 'react';

export default function Section({ children, className = '', py = true }) {
  return (
    <section
      className={`${py ? 'py-12 sm:py-24' : ''} px-4 sm:px-6 ${className}`}
    >
      {children}
    </section>
  );
}
